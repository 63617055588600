

// Colors
$color-light-blue: #00bee2;
$color-light-gray: #8a8b8c;
$color-dark-gray: #929292;
$color-dark-teal: #006582;
$color-deep-teal: #193139;
$color-white: #f7f7f7;
$color-success: #2e984c;
$color-error: #b83737;
$color-very-light-blue: #f9fcfd;
$color-gold: #ffd700;
$color-black: #1e1e1e;

// Lengths
$drawer-width: 308px;
$padding-home: 24px;

// Z-Index
$sidebar-z-index: 100;

$wave-background: url('~/public/assets/pngs/TechBG.png');
